'use client';
import React from 'react';
import ReactDOM from 'react-dom';
import * as AlertDialog from '@radix-ui/react-alert-dialog';
import { LANGUAGES_CONTENT } from '@/constants/language';
import { StorageService } from '@/service/StorageService';

const openConfirmAlert = ({ title, message, onOk }) => {
  const CONTENT =
    LANGUAGES_CONTENT[StorageService.getLanguage()].dialog.confirmAlert;
  const handleConfirm = () => {
    onOk();
    handleCleanup();
  };

  const closeAlert = () => {
    ReactDOM.unmountComponentAtNode(alertComponent);
    // document.getElementById('alert-dialog-root').innerHTML = '';
  };
  const div = document.createElement('div');
  document.body.appendChild(div);

  function handleCleanup() {
    const unmountResult = ReactDOM.unmountComponentAtNode(div);
    if (unmountResult && div.parentNode) {
      div.parentNode.removeChild(div);
    }
  }

  const alertComponent = (
    <AlertDialog.Root
      open={true}
      onOpenChange={closeAlert}
      id="alert-dialog-root"
    >
      <AlertDialog.Portal>
        <AlertDialog.Overlay className="fixed inset-0 bg-blackA6" />
        <AlertDialog.Content className="fixed top-[50%] left-[50%] max-h-[85vh] w-[90vw] max-w-[500px] bg-slate-950 rounded-lg border border-white/40 translate-x-[-50%] translate-y-[-50%] p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none">
          <AlertDialog.Title className="text-white m-0 text-[17px] font-medium">
            {title}
          </AlertDialog.Title>
          <AlertDialog.Description className="text-slate-400 mt-4 mb-5 text-[15px] leading-normal">
            {message}
          </AlertDialog.Description>
          <div className="flex justify-end gap-4">
            <button
              onClick={handleCleanup}
              className="text-accent-color  hover:opacity-70 border border-accent-color focus:shadow-accent-color/40 inline-flex h-[35px] items-center justify-center rounded-[4px] px-[15px] font-medium leading-none outline-none focus:shadow-[0_0_0_2px]"
            >
              {CONTENT.cta.cancel}
            </button>
            <button
              onClick={handleConfirm}
              className="text-white bg-accent-color hover:bg-red5 focus:shadow-red7 inline-flex h-[35px] items-center justify-center rounded-[4px] px-[15px] font-medium leading-none outline-none focus:shadow-[0_0_0_2px]"
            >
              {CONTENT.cta.confirm}
            </button>
          </div>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  );

  ReactDOM.render(alertComponent, div);

  return {
    handleCleanup,
  };
};

export default openConfirmAlert;
